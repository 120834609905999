import { graphql } from 'gatsby'
import React, { useCallback, useMemo, useState } from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import { ContentContainer } from '../components/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components'
import InsetTitle from '../components/inset-title'
import Footer from '../components/project-footer';
import Overview from '../components/overview';
import ConditionallyRender from '../components/utils/conditionally-render';
import Seo from '../components/seo';
import FadeIn from '../components/fade-in';


export default function Project({ data: { project, nextProject } }) {


    const reflection = useMemo(() => {
        return {
            reflection: project.reflection,
            client: project.reflectionClient,
        }
    }, [project.reflection,project.reflectionClient])

    const stats = useMemo(() => {

        const { address, projectValue, ownership, timeline, contractedTo } = project
        return { address, projectValue, ownership, timeline, contractedTo }

    }, [project])


    return (
        <Layout>
            <Seo title={project.title} />
            <Hero image={project.featuredImage} >
                <h2 className='h2 white'>{project.name}</h2>
            </Hero>
            <ContentContainer className='pt-[50px]' >
                <FadeIn>
                    <Overview className='pb-[175px] md:pb-[0px] md:border-none   ' overview={project.overview} stats={stats} />
                </FadeIn>
                <ConditionallyRender when={project.gallery.length > 1} >
                    <FadeIn>
                      <Gallery project={project}/>
                    </FadeIn>
                </ConditionallyRender>
                <FadeIn>
                    <Reflection reflection={reflection} />
                </FadeIn>
            </ContentContainer>
            <Footer project={nextProject} />
        </Layout>
    )
}



function Reflection({ reflection }) {
    return (
        <ConditionallyRender when={reflection.reflection && reflection.client}>
        <div className='pt-[40px] bt1 grid grid-cols-2 pb-[220px] md:grid-cols-1 md:pb-[120px] '>
            <div>
                <h4 className='h4 md:text-black md:mb-[50px] '>REFLECTIONS</h4>
            </div>
            <div>
                <div className='mb-[50px] md:mb-[30px] '>
                    <InsetTitle content={reflection.reflection} />
                </div>
                <h4 className='h4'>{reflection.client}</h4>
            </div>
        </div>
        </ConditionallyRender>
    )
}

const Wrapper = styled.div`
    padding-top:60%;
    position:relative ;


`

const ImageWrapper = styled.div`
    top:0px;
    position:absolute ;
    height:100%;
    transition: ${props => props.active ? `opacity 0.5s ease 0.0s` : `opacity 0.5s ease 0s`} ;  
    opacity: ${props => props.active ? 1 : 0} ;
`

const ImageControlWrapper = styled.div`
    display:grid;
    grid-auto-flow: column;
    grid-template-rows:1fr;
    
`

const ImageControl = styled.button`
  
    max-width:120px;
    padding:15px;
    display:flex ;
    justify-content:center;
    align-items:center;
    transition:0.5s ease border ;
    border: ${props => props.active ? '1px solid #E6E6E6' : '1px solid transparent'} ;
   
    position:relative ;
    @media(pointer: coarse),(max-width:768px) {
        padding:5px;
    }
   
   
`

function Gallery({project}) {
    const [currentImage, setCurrentImage] = useState(0)
    return (
        <div className='bt1 pt-[40px] grid-cols-6 grid mb-[180px] md:grid-cols-1  m-b-n md:pt-[60px] md:mb-[60px] md:border-none '>
            <div className='col-span-2 md:mb-[20px] '>
                <h4 className='h4'>Project</h4>
                <h3 className='h3'>Gallery</h3>
            </div>
            <div className=' col-span-4'>
                <h3 className='h4 mb-[20px] relative md:hidden'>{currentImage + 1}/{project.gallery.length}</h3>
                <ImagePicker setCurrentImage={setCurrentImage} currentImage={currentImage} images={project.gallery} />

            </div>
        </div>
        )
}


function ImagePicker({ images,currentImage, setCurrentImage}) {
    

    const isCurrentImage = useCallback((currentImage, index) => {
        return currentImage === index
    }, [])

    const getFit = useCallback((height,width) =>{
        if(height > width){
            return 'contain'
        }
        return 'cover'
    },[])
    return (
        <>
            <Wrapper className=''>
                {images.map((image, index) => {
                    return (
                        <ImageWrapper active={isCurrentImage(currentImage, index)}>
                            <GatsbyImage objectFit={getFit(image.height,image.width)} className='h-[100%]' image={image?.gatsbyImageData} />
                        </ImageWrapper>

                    )
                })}
            </Wrapper>
            <div className='flex justify-center '>
                <ImageControlWrapper className='gap-x-[5%] pt-[30px] md:pt-[20px] md:gap-x-[0px] '>
                    {images.map((image, index) => {
                        return (
                            <div className='flex flex-col justify-center'>
                                <ImageControl onClick={() => setCurrentImage(index)} active={isCurrentImage(currentImage, index)} >

                                    <div className='flex flex-col'>
                                        <GatsbyImage className='h-[100%]' image={image?.gatsbyImageData} />
                                    </div>

                                </ImageControl>
                            </div>

                        )
                    })}
                </ImageControlWrapper>
            </div>
        </>
    )
}



export const query = graphql`
    query Project($id: String,$nextId: String){
        project:datoCmsProject(id: {eq:$id}){
            name
            id
            featuredImage {
                gatsbyImageData
            }
            gallery{
                gatsbyImageData(layout:CONSTRAINED,width:1600,,outputPixelDensities:[0.02,0.02])
                width
                height
            }
            overview {
                content
            }
            address
            projectValue
            timeline
            contractedTo
            ownership
            reflection
            reflectionClient 

        }
        nextProject:datoCmsProject(id: {eq:$nextId}){
            name
            slug
            featuredImage {
                gatsbyImageData
            }
        }
    }
`