import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components';
import InsetTitle from './inset-title'

export default function Overview({ overview, stats, className }) {

    const getTitle = useCallback((index) => {
        if (index === 0) return 'OVERVIEW';
        return ''
    }, [])

    return (
        <div className={' grid grid-cols-2  bt1  md:grid-cols-1  ' + className}>
            <div className='pt-[40px] pr-[110px] md:pr-[0px]  md:hidden'>
                {overview.map(({ content }, index) => {
                    return (<div className={index !== overview.length - 1 ? 'mb-[50px] ' : ''}>
                        <InsetTitle contentClass='h3 ' title={getTitle(index)} content={content} />
                    </div>)
                })}

            </div>
            <div className=' hidden pt-[30px] md:block mb-[75px] '>
                {overview.map(({ content }, index) => {
                    if (index === 0) {
                        return (
                            <div className={index !== overview.length - 1 ? 'mb-[50px] ' : ''}>
                                <InsetTitle contentClass='h3 ' title={getTitle(index)} content={content} />
                            </div>
                        )
                    }
                    return (<p className='p mb-[50px]'>{content}</p>)
                })}

            </div>
            <div>
                <Table stats={stats} />
            </div>
        </div>
    )
}

const Wrapper = styled.div`
@media(pointer: coarse),(max-width:768px){
    border-top:1px solid #E6E6E6;
}
`



function Table({ stats }) {

    const { address, projectValue, ownership, timeline, contractedTo } = stats

    const rows = useMemo(() => [
        {
            name: 'ADDRESS',
            content: address
        },
        {
            name: 'PROJECT VALUE',
            content: projectValue
        },
        {
            name: 'OWNERSHIP',
            content: ownership
        },
        {
            name: 'Timeline',
            content: timeline
        },
        {
            name: 'CONTRACTED TO',
            content: contractedTo
        }
    ], [address,projectValue,ownership,contractedTo,timeline])



    return (
        <Wrapper className='flex flex-col pt-[15px] md:pt-[0px] '>
            {rows.map((row) => {
                return <TableRow name={row.name} content={row.content} />
            })}
        </Wrapper>
    )
}

const Row = styled.div`
    display:grid ;
    grid-template-columns:1fr 2fr;
    padding-top:20px;
    padding-bottom:20px;
    @media(pointer: coarse) ,(max-width:768px){
        grid-template-columns:1fr;
    }
`

function TableRow({ name, content }) {
    return (
        <Row className='bb1'>
            <div className='flex flex-col justify-center md:mb-[5px] pr-[15px] '>
                <h4 className='h4 black'>{name}</h4>
            </div>
            <div className='flex flex-col justify-center '>
                <p className='text-[#3D3D3D]  '>{content}</p>
            </div>
        </Row>
    )
}


