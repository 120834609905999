import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import Logo from './icons/logo'
import { isMobile } from 'react-device-detect';
import Link from './link'
import { MenuContext } from '../context/menu-provider'
import { GatsbyImage } from 'gatsby-plugin-image'
import { CreatedBy, Contact } from './shared/footer'
import { useInView } from 'react-intersection-observer';


const Wrapper = styled.div`
    background:#3D3D3D;
    position:relative ;
    pointer-events:${props => props.disabled ? 'none' : 'all'};
  
`

const InnerWrapper = styled.div`
    padding-top:30px;
    padding-left:60px;
    padding-right:60px;
    padding-bottom:50px;
    z-index: 20;
    position:relative ;  
    @media(pointer: coarse) ,(max-width:768px){
        padding-top:50px;
        padding-left:30px;
        padding-right:30px;
    }

`

const HoverH2 = styled.h2`
    padding-bottom:10px;
    position:relative ;

    &:after{
        content:' ' ;
        position:absolute;
        bottom:0px;
        left:0px;
        height:1px;
        width:0%;;
        background:white;
        transition:0.85s ease width;
    }
    ${props => {
        if (!props.mobile) {
            return `${Wrapper}:hover & {    
                &:after{
                    width:100%;
                }
            }
            `
        } else {
            if (props.active) {
               return `
                &:after{
                    width:100%;
                }
               `
            }
        }
    }}

        ${Wrapper}:hover & {    
            &:after{
                width:100%;
            }
        }
    }

`

const Overlay = styled.div`
    position:absolute ;
    height:100%;
    width:100%;
    z-index: 10;
    transition:0.85s ease opacity ;
    left:0px;
    top:0px;
    opacity:0;

    ${props => {
        if (!props.mobile) {
            return `${Wrapper}:hover & {
                opacity: 0.9;
            }
            `
        } else {
            if (props.active) {
                return `opacity:0.9;`
            }
        }
    }}

`
export default function ProjectFooter({ project }) {

    const {ref,inView} = useInView({threshold:0.5})

    const {setHide} = useContext(MenuContext)

    useEffect(() =>{
        if(inView){
             setHide(true)
        }else{
            setHide(false)
        }
    },[inView,setHide])

    return (
        <Wrapper ref={ref}  >
            <Link to={`/projects/${project.slug}`}>

                <InnerWrapper className='grid grid-cols-2 md:grid-cols-1'>
                    <div className='flex items-end'>

                    </div>
                    <div className='flex flex-col'>
                        <div className='pb-[365px]'>

                            <h4 className='h4 white mb-[20px]'>Next</h4>
                            <div className='flex'>
                                <div>
                                    <HoverH2 active={inView} mobile={isMobile} className='h2 white'>{project.name}</HoverH2>
                                </div>
                            </div>
                        </div>

                    </div>
                </InnerWrapper>
                <Overlay active={inView} mobile={isMobile} >
                    <GatsbyImage className='h-[100%] w-[100%] brightness-75' image={project.featuredImage.gatsbyImageData} />
                </Overlay>
            </Link>
            <FooterOverlay />
        </Wrapper>
    )
}


function FooterOverlay() {


    return (
        <div className='absolute z-20 bottom-0 w-[100%]'>
            <InnerWrapper>
                <div className='grid grid-cols-2 md:grid-cols-1'>
                    <div className='flex items-end md:mb-[30px] '>
                        <Logo />
                    </div>
                    <div className='flex justify-between items-end  bottom-0px md:flex-col md:items-start' >
                        <Contact />
                        <div className='md:pt-[30px] '>
                            <CreatedBy />
                        </div>
                    </div>
                </div>
            </InnerWrapper>
        </div>
    )
}